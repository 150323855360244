// Assuming you have a canvas element and a 2D rendering context called ctx

// Function to draw the canvas
const drawCanvas = (canvas, ctx,inputCoordinates) => {
    // Convert the input coordinates to numbers
    const coordinates = inputCoordinates.map(coord => ({
      x: Number(coord.x),
      y: Number(coord.y)
    }));
  
    // Set the size of the canvas and the scale of the graph
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    const xScale = canvas.width / 2 / (Math.max(...coordinates.map(c => Math.abs(c.x))) + Math.abs(Math.min(...coordinates.map(c => c.x))));
    const yScale = canvas.height / 2 / (Math.max(...coordinates.map(c => Math.abs(c.y))) + Math.abs(Math.min(...coordinates.map(c => c.y))));
  
    // Set the origin to the center of the screen
    ctx.translate(canvas.width / 2, canvas.height / 2);
  
    // Clear the canvas
    ctx.clearRect(-canvas.width / 2, -canvas.height / 2, canvas.width, canvas.height);
  
    // Plot the points
    ctx.fillStyle = "green";
    ctx.font = "bold 12px Arial";
    coordinates.forEach(function(coord, index) {
      const x = coord.x * xScale;
      const y = -coord.y * yScale;
      ctx.beginPath();
      ctx.arc(x, y, 5, 0, 2 * Math.PI);
      ctx.fill();
      ctx.fillText(index, x + 7, y - 7);
    });
  
    // Draw the x and y axes
    ctx.beginPath();
    ctx.moveTo(-canvas.width / 2, 0);
    ctx.lineTo(canvas.width / 2, 0);
    ctx.moveTo(0, -canvas.height / 2);
    ctx.lineTo(0, canvas.height / 2);
    ctx.stroke();
  
    // Plot the graph
    ctx.strokeStyle = "blue";
    ctx.beginPath();
    ctx.moveTo(coordinates[0].x * xScale, -coordinates[0].y * yScale);
    for (let i = 1; i < coordinates.length; i++) {
      ctx.lineTo(coordinates[i].x * xScale, -coordinates[i].y * yScale);
    }
    ctx.stroke();
  };
  
  export default drawCanvas;
  