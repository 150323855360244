import React, { useState, useRef, useEffect } from 'react';
import drawCanvas from './drawCanvas';

const MyForm = () => {
  const [input1X, setInput1X] = useState('');
  const [input1Y, setInput1Y] = useState('');
  const [input2X, setInput2X] = useState('');
  const [input2Y, setInput2Y] = useState('');

  const canvasRef = useRef(null);
  const ctxRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctxRef.current = ctx;
  }, []);

  const handleInput1XChange = (e) => {
    setInput1X(e.target.value);
  };

  const handleInput1YChange = (e) => {
    setInput1Y(e.target.value);
  };

  const handleInput2XChange = (e) => {
    setInput2X(e.target.value);
  };

  const handleInput2YChange = (e) => {
    setInput2Y(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const coordinates = [
      { x: input1X, y: input1Y },
      { x: input2X, y: input2Y },
    ];
    const ctx = ctxRef.current;
    const canvas = canvasRef.current;
    drawCanvas(canvas,ctx, coordinates);
  };

  return (
    <div>
      <canvas ref={canvasRef} width={800} height={600} />
      <form onSubmit={handleSubmit}>
        <label>
          Input 1 - X:
          <input type="text" value={input1X} onChange={handleInput1XChange} />
        </label>
        <br />
        <label>
          Input 1 - Y:
          <input type="text" value={input1Y} onChange={handleInput1YChange} />
        </label>
        <br />
        <label>
          Input 2 - X:
          <input type="text" value={input2X} onChange={handleInput2XChange} />
        </label>
        <br />
        <label>
          Input 2 - Y:
          <input type="text" value={input2Y} onChange={handleInput2YChange} />
        </label>
        <br />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default MyForm;
